import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import {MapMouseEvent} from 'mapbox-gl';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import {fromEvent, Subscription} from 'rxjs';
import {featureCollection, point} from '@turf/helpers';
import bbox from '@turf/bbox';
import greatCircle from '@turf/great-circle';

import {MigrationService} from '../shared/migration/migration.service';
import {Point, TimePeriod} from '../shared/migration/migration';

const LINES_SOURCE = 'lines_source';
const LINES_LAYER = 'lines_layer';
const POINTS_SOURCE = 'points_source';
const POINTS_LAYER = 'points_layer';

@Component({
    selector: 'dna-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

    map: mapboxgl.Map;
    draw: MapboxDraw; // just used with MapboxDraw
    lineIdHovered: number;
    lineIdSelected: number;

    private currentTimePeriodSub: Subscription;
    private polygonsSub: Subscription;
    private showFinalSub: Subscription;
    private subscriptions = new Subscription();

    private animateId: any;

    constructor(
        private migrationService: MigrationService, private zone: NgZone
    ) {
        (mapboxgl as any).accessToken = mapboxgl.accessToken ||
            'pk.eyJ1IjoiYW5jZXN0cnltYXBib3giLCJhIjoiNllqcGhKYyJ9.p9QKjx4kc2E_55jLTmDw0Q';
    }

    ngOnInit() {
        this.map = new mapboxgl.Map({
            container: 'map',
            attributionControl: false,
            style: 'mapbox://styles/ancestrymapbox/clfa1xocy000k01mlxj24qpcj',
            dragRotate: false
        });

        const mapDiv = document.getElementById('map') as HTMLElement;

        new ResizeObserver(() => this.map.resize())
            .observe(mapDiv);

        this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

        this.draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true
            }
        });
        this.map.addControl(this.draw);

        this.currentTimePeriodSub = this.migrationService.currentTimePeriod.subscribe(timePeriod => {
            if (!!timePeriod.clusterPoints) {
                this.drawMigrationPoints(timePeriod);
            }
            if (!!timePeriod.clusterLines) {
                this.drawMigrationLines(timePeriod);
            }
        });

        this.polygonsSub = this.migrationService.polygons.subscribe(limits => {
            const polyIds = limits.map(limit => limit.polygon.id);
            const featureIds = this.draw.getAll().features.map(feature => feature.id);
            featureIds.forEach(featureId => {
                if (polyIds.indexOf(featureId) < 0) {
                    this.draw.delete(featureId);
                }
            });
            limits.forEach(limit => {
                if (featureIds.indexOf(limit.polygon.id)) {
                    this.draw.add(limit.polygon);
                }
            });
        });

        this.showFinalSub = this.migrationService.showFinal.subscribe(show => {
            if (this.map.getLayer(LINES_LAYER)) {
                this.map.setFilter(LINES_LAYER, show ? ['==', 'keep', true] : null);
            }
            if (this.map.getLayer(POINTS_LAYER)) {
                this.map.setFilter(POINTS_LAYER, show ? ['==', 'keep', true] : null);
            }
        });

        this.subscriptions.add(fromEvent(this.map, 'draw.create').subscribe((event: any) => {
            if (event.features && event.features.length > 0 && event.features[0].geometry.type === 'Polygon') {
                this.migrationService.polygonDrawn(event.features[0]);
            }
        }));
        this.subscriptions.add(fromEvent(this.map, 'draw.delete').subscribe((event: any) => {
            if (event.features && event.features.length > 0 && event.features[0].geometry.type === 'Polygon') {
                this.migrationService.deletePolygonLimit(event.features[0].id);
            }
        }));
        this.subscriptions.add(fromEvent(this.map, 'mousemove').subscribe((event: MapMouseEvent) => {
            if (this.map.getLayer(LINES_LAYER)) {
                const features: any[] = this.map.queryRenderedFeatures(event.point, {layers: [LINES_LAYER]});
                let hoverId;
                if (features && features.length) {
                    this.map.getCanvas().style.cursor = 'pointer';
                    hoverId = features[0].id;
                } else {
                    this.map.getCanvas().style.cursor = '';
                    hoverId = null;
                }
                if (hoverId !== this.lineIdHovered) {
                    if (hoverId) {
                        this.map.setFeatureState({id: hoverId, source: LINES_SOURCE}, {hover: true});
                    }
                    if (this.lineIdHovered) {
                        this.map.setFeatureState({id: this.lineIdHovered, source: LINES_SOURCE}, {hover: false});
                    }
                    this.lineIdHovered = hoverId;
                }
            }
        }));
        this.subscriptions.add(fromEvent(this.map, 'click').subscribe((event: MapMouseEvent) => {
            if (this.map.getLayer(LINES_LAYER)) {
                let selectedId;
                if (this.lineIdHovered) {
                    selectedId = this.lineIdHovered;
                } else {
                    selectedId = null;
                }
                if (selectedId !== this.lineIdSelected) {
                    if (selectedId) {
                        this.map.setFeatureState({id: selectedId, source: LINES_SOURCE}, {selected: true});
                    }
                    if (this.lineIdSelected) {
                        this.map.setFeatureState({id: this.lineIdSelected, source: LINES_SOURCE}, {selected: false});
                    }
                    this.lineIdSelected = selectedId;
                    this.migrationService.setSelectedLine(this.lineIdSelected);
                }
            }
        }));
    }

    ngOnDestroy() {
        this.stopAnimateLines();
        this.currentTimePeriodSub.unsubscribe();
        this.polygonsSub.unsubscribe();
        this.showFinalSub.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    private drawMigrationLines(data: TimePeriod) {
        const linesGeoJson = this.getLinesGeoJson(data);
        const existentLinesSource = this.map.getSource(LINES_SOURCE) as mapboxgl.GeoJSONSource;
        if (existentLinesSource) {
            existentLinesSource.setData(linesGeoJson);
        } else {
            if (!linesGeoJson.features.length) {
                return;
            }
            const geoJson = this.getGeoJsonSource(linesGeoJson);
            geoJson.lineMetrics = true;
            this.map.addSource(LINES_SOURCE, geoJson);
        }

        if (!this.map.getLayer(LINES_LAYER)) {
            this.map.addLayer({
                'id': LINES_LAYER,
                'type': 'line',
                'source': LINES_SOURCE,
                'paint': {
                    'line-width': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        4,
                        2
                    ],
                    'line-color': [
                        'case',
                        ['boolean', ['feature-state', 'selected'], false],
                        '#FFFF00',
                        '#FFF'
                    ],
                    'line-opacity': [
                        'case',
                        ['boolean', ['get', 'keep'], true],
                        0.85,
                        0.15
                    ],
                    'line-dasharray': [0, 1, 1]
                },
                layout: {
                    'line-cap': 'butt',
                }
            }, 'place-label');
        }
        setTimeout(() => {
            if (!this.animateId && !!this.map.getLayer(LINES_LAYER)) {
                this.zone.runOutsideAngular(() => {
                    this.animateLines(0.0);
                });
            }
        }, 500);
    }

    private animateLines(lineOffset: number) {
        lineOffset = lineOffset >= 2.0 ? 0 : +(lineOffset + 0.1).toFixed(1);
        const dashArray = lineOffset > 1 ? [0, lineOffset - 1, 1, 2 - lineOffset] : [lineOffset, 1, 1 - lineOffset];
        // tslint:disable-next-line:max-line-length
        this.map.setPaintProperty(LINES_LAYER, 'line-dasharray', dashArray);
        this.animateId = requestAnimationFrame(() => this.animateLines(lineOffset));
    }

    private stopAnimateLines() {
        if (this.animateId) {
            cancelAnimationFrame(this.animateId);
            this.animateId = null;
        }
    }

    private drawMigrationPoints(data: TimePeriod) {
        const pointsGeoJson = this.getPointGeoJson(data.clusterPoints);
        if (!!this.map.getSource(POINTS_SOURCE)) {
            const source: any = <mapboxgl.GeoJSONSource>this.map.getSource(POINTS_SOURCE);
            source.setData(pointsGeoJson);
        } else {
            this.map.addSource(POINTS_SOURCE, this.getGeoJsonSource(pointsGeoJson));
        }

        const circleRadiusStops = [];
        for (let zoom = 0; zoom <= 22; zoom++) {
            for (let i = 1; i <= 11; i += 5) {
                circleRadiusStops.push([
                    {zoom: zoom, value: i},
                    512 / 360.0 * Math.pow(2, zoom) * ((data.pointClusterLvl * 0.75) * (i / 11.0)) * 1.15
                ]);
            }
        }
        circleRadiusStops.sort((a, b) => {
            if (a[0].zoom !== b[0].zoom) {
                return a[0].zoom - b[0].zoom;
            }
            return a[0].value - b[0].value;
        });

        if (!this.map.getLayer(POINTS_LAYER)) {
            this.map.addLayer({
                'id': POINTS_LAYER,
                'type': 'circle',
                'source': POINTS_SOURCE,
                'paint': {
                    'circle-color': '#fff',
                    'circle-radius': {
                        'property': 'level',
                        'stops': circleRadiusStops
                    },
                    'circle-opacity': 0.9
                }
            }, 'place-label');
        } else {
            this.map.setPaintProperty(POINTS_LAYER, 'circle-radius', {
                'property': 'level',
                'stops': circleRadiusStops
            });
        }
    }

    private getBoundsFromGeoJson(feature: GeoJSON.Feature<GeoJSON.GeometryObject> |
        GeoJSON.FeatureCollection<GeoJSON.GeometryObject>): number[][] {
        const bb = bbox(feature);
        return [[bb[0], bb[1]], [bb[2], bb[3]]];
    }

    private getLinesGeoJson(timePeriod: TimePeriod): GeoJSON.FeatureCollection<GeoJSON.LineString | GeoJSON.MultiLineString> {
        const features: GeoJSON.Feature<GeoJSON.LineString | GeoJSON.MultiLineString>[] = [];
        timePeriod.clusterLines.filter(l => l.count >= timePeriod.minLineCount).forEach((l, i) => {
            const geoJson = this.getArc(l.coords, {level: l.level, keep: l.keep, id: l.id});
            geoJson.id = l.id;
            features.push(geoJson);
        });
        return featureCollection(features);
    }

    private getArc([startLngLat, endLngLat]: [[number, number], [number, number]], properties: object): GeoJSON.Feature<GeoJSON.LineString | GeoJSON.MultiLineString> {
        return greatCircle(startLngLat, endLngLat, {properties, npoints: 150, offset: 15});
    }

    private getPointGeoJson(points: Point[]): GeoJSON.FeatureCollection<GeoJSON.Point> {
        const features: GeoJSON.Feature<GeoJSON.Point>[] = [];
        points.forEach((p, i) => {
            features.push(point(p.coords, {count: p.count, level: p.level, keep: p.keep}));
        });
        return featureCollection(features);
    }

    private getGeoJsonSource(geoJson: GeoJSON.Feature<GeoJSON.GeometryObject> |
        GeoJSON.FeatureCollection<GeoJSON.GeometryObject>): mapboxgl.GeoJSONSourceRaw {
        return {
            type: 'geojson',
            data: geoJson
        };
    }
}
